'use client';
import { Program } from '@/types';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

const ProgramCard = ({
  program,
  admission
}: {
  program: Program;
  admission?: boolean;
}) => {
  const router = useRouter();
  const viewProgram = () => {
    router.push('/programDetails');
  };

  return (
    <Box sx={styles.card}>
      <Grid container spacing={2}>
        <Grid
          sx={{
            display: {
              xs: 'block',
              sm: admission ? 'none' : 'block',
              md: 'block'
            }
          }}
          item
          xs={12}
          sm={5}
          md={4}
        >
          <Image
            src={program.imageUrl || '/assets/png/programImage.png'}
            alt="programImage"
            width={admission ? 224 : 364}
            height={admission ? 209 : 251}
            style={{
              alignSelf: 'center',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              borderRadius: '12px'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={admission ? 12 : 7} md={8}>
          <Box sx={styles.cardInfo}>
            <Box sx={styles.cardDetails}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row', gap: 1 }
                  }}
                >
                  {admission && (
                    <Box
                      sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}
                    >
                      <Image
                        src="/assets/png/university.png"
                        alt="programImage"
                        width={500}
                        height={116}
                        style={{
                          alignSelf: 'center',
                          width: '100%',
                          objectFit: 'cover',
                          borderRadius: '12px'
                        }}
                      />
                    </Box>
                  )}

                  <Typography variant="h5" fontWeight="600">
                    {program.title}
                  </Typography>
                  <Button
                    sx={{
                      mt: 2,
                      maxWidth: '200px',
                      width: '100%',
                      alignSelf: 'flex-start',
                      ml: { xs: 0, sm: 1 },
                      display: { xs: 'none', sm: 'block' }
                    }}
                    variant="contained"
                    onClick={viewProgram}
                  >
                    View Program
                  </Button>
                </Box>
                <Box sx={styles.infoRow}>
                  <Image
                    src={`/assets/png/partner1.png`}
                    alt={`partner-1`}
                    width={90}
                    height={25}
                  />
                  <Box sx={styles.rating}>
                    <Image
                      src={`/assets/svg/star.svg`}
                      alt={`star`}
                      width={24}
                      height={24}
                    />
                    <Typography variant="body1" fontWeight="500">
                      4
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.infoRow}>
                  <Image
                    src={`/assets/svg/location.svg`}
                    alt={`location`}
                    width={24}
                    height={24}
                  />
                  <Typography variant="body1">{program.location}</Typography>
                </Box>
                {program.isScholarshipAvailable && (
                  <Box sx={styles.infoRow}>
                    <Image
                      src={`/assets/svg/heart.svg`}
                      alt={`heart`}
                      width={24}
                      height={24}
                    />
                    <Typography
                      fontWeight="500"
                      variant="body1"
                      color="primary"
                    >
                      Scholarships Available
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box gap={1} sx={styles.programDetails}>
                <Box sx={styles.detailRow}>
                  <Image
                    src={`/assets/svg/calendar.svg`}
                    alt={`calender`}
                    width={24}
                    height={24}
                  />
                  <Box>
                    <Typography
                      variant="body2"
                      sx={styles.statsText}
                      color="error"
                    >
                      Deadline
                    </Typography>
                    <Typography
                      textAlign="center"
                      variant="body1"
                      fontWeight="600"
                      sx={styles.statsText}
                      color="error"
                    >
                      {program.deadline}
                    </Typography>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem sx={styles.divider} />
                <Box sx={styles.detailRow}>
                  <Image
                    src={`/assets/svg/dollar-circle.svg`}
                    alt={`free icon`}
                    width={24}
                    height={24}
                  />
                  <Box>
                    <Typography sx={styles.statsText} variant="body2">
                      Fee (Semester)
                    </Typography>
                    <Typography
                      sx={styles.statsText}
                      variant="body1"
                      fontWeight="600"
                    >
                      {program.fee}
                    </Typography>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem sx={styles.divider} />
                <Box sx={styles.detailRow}>
                  <Image
                    src={`/assets/svg/teacher.svg`}
                    alt={`calender`}
                    width={24}
                    height={24}
                  />
                  <Box>
                    <Typography sx={styles.statsText} variant="body2">
                      Study Mode
                    </Typography>
                    <Typography
                      sx={styles.statsText}
                      variant="body1"
                      fontWeight="600"
                    >
                      {program.studyMode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Button
        sx={styles.viewProgramButton}
        onClick={viewProgram}
        variant="contained"
      >
        View Program
      </Button>
    </Box>
  );
};
export default ProgramCard;

const styles = {
  card: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    borderRadius: 2,
    backgroundColor: 'white',
    padding: 2
  },
  cardContent: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    gap: 3,
    mt: 2
  },
  programImage: {
    alignSelf: 'center',
    width: 'auto',
    height: 'auto'
  },
  cardInfo: {
    display: 'flex'
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    mt: 2
  },
  rating: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1
  },
  programDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    }
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    mt: 1
  },
  divider: {
    margin: { xs: 0.5, lg: 1 },
    display: {
      xs: 'none',
      md: 'block'
    }
  },
  viewProgramButton: {
    maxWidth: {
      xs: '100%',
      md: '200px'
    },
    mt: {
      xs: 2,
      lg: 0
    },
    width: '100%',
    height: '56px',
    display: { xs: 'block', sm: 'none' }
  },
  statsText: { fontSize: { xs: 12, sm: 14 } }
};
